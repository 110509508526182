<template>
  <section>
    <v-row class="stats-rows" justify="space-around">
      <v-col v-for="(item, index) in stats" :key="index" cols="12" md="4">
        <v-sheet class="stats-card">
          <v-sheet :elevation="6" class="stats-card__inner mx-auto">
            <div class="stats-card__inner-title">{{ item.title }}</div>
            <div class="stats-card__inner-value">
              {{ item.value }}
              <div class="stats-card__inner-second-value">
                {{
                  item.secondValue != undefined
                    ? " (" + item.secondValue + ")"
                    : ""
                }}
              </div>
            </div>
          </v-sheet>
        </v-sheet>
      </v-col>
    </v-row>
    <!-- <EnergyChart /> -->
  </section>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {
    EnergyChart: () => import("@/components/charts/EnergyChart"),
  },
  data: () => ({
    stats: [
      {
        title: "Orders",
        value: 0,
        secondValue: 0,
      },
      {
        title: "Drivers",
        value: 0,
      },
      {
        title: "Customers",
        value: 0,
      },
    ],
  }),
  computed: {
    ...mapGetters(["user", "strapi"]),
  },
  async mounted() {
    var ordersUnassignedCount = await this.strapi.getEntryCount("orders", {
      driver_gte: 0,
    });
    var ordersCount = await this.strapi.getEntryCount("orders");
    var driversCount = await this.strapi.getEntryCount("users", { role: 5 });
    var customersCount = await this.strapi.getEntryCount("customers");

    this.stats[0].value = ordersCount;
    this.stats[0].secondValue = ordersCount - ordersUnassignedCount;
    this.stats[1].value = driversCount;
    this.stats[2].value = customersCount;

    // console.log(this.stats);
  },
};
</script>
<style lang="scss" scoped>
.stats-rows {
  max-width: 1000px;
  margin: 0 auto;
}

.stats-card {
  background: transparent;
}

.stats-card__inner {
  // background: #4d5f6d;
  height: 150px;
  width: 250px;
}

.stats-card__inner-title {
  color: #3e688a;
  font-size: 32px;
  text-align: center;
  padding-top: 10px;
}

.stats-card__inner-value {
  color: rgb(217, 55, 49);
  font-size: 40px;
  text-align: center;
  padding-top: 10px;
  font-weight: 500;

  display: flex;
  justify-content: center;
}

.stats-card__inner-second-value {
  color: black;
  padding-top: 10px;
  margin: 0 10px;
  font-size: 25px;
}
</style>
