<template>
  <section class="waiting-for-approval">
    <!--  -->
    <span>Waiting For Approval</span>
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.waiting-for-approval {
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
