<template>
  <div class="dashboard-container">
    <component :is="requiredDashboard" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AdminDashboardPage from "./AdminDashboardPage";
import CustomerDashboardPage from "./CustomerDashboardPage";
import DriverDashboardPage from "./DriverDashboardPage";
import WaitingForApprovalPage from "./WaitingForApprovalPage";

export default {
  name: "Dashboard",
  components: {
    CustomerDashboardPage,
    AdminDashboardPage,
    DriverDashboardPage,
    WaitingForApprovalPage,
  },
  data() {
    return {
      requiredDashboard: "DriverDashboardPage",
    };
  },
  computed: {
    ...mapGetters(["role"]),
  },
  created() {
    switch (this.role.name) {
      case "Admin":
        this.requiredDashboard = "AdminDashboardPage";
        break;
      case "ClientUser":
      case "ClientAdmin":
        this.requiredDashboard = "CustomerDashboardPage";
        break;
      case "Driver":
        this.requiredDashboard = "DriverDashboardPage";
        break;
      case "Authenticated":
        this.requiredDashboard = "WaitingForApprovalPage";
        break;
    }
  },
};
</script>
<style scoped>
/* .dashboard-container {
  background-color: rgb(240, 242, 245);
} */
</style>
