<template>
  <section>
    <!--  -->
    Client
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped></style>
